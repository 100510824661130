import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import type {Ref} from 'react';
import React from 'react';
import type {DottedButtonProps} from '@Components/dotted-icon-button/dotted-icon-button.types';
import type { ButtonProps} from '@Components/button';
import {Button} from '@Components/button';
import './controlled-list-item-type-button.scss';

export interface ControlledListItemTypeButtonProps extends BaseControlledListItem {
  buttonProps: ButtonProps;
  isSelected: false;
  text: '' | string;
}

export const ControlledListItemTypeButton = React.forwardRef(
  ({id = '', className = '', buttonProps = {} as DottedButtonProps, onClick = $.noop, ...props}: ControlledListItemTypeButtonProps, ref: Ref<HTMLLIElement>) => {
    return (
      <li
        onKeyDown={$.noop}
        role="button"
        key={id}
        ref={ref}
        onClick={(): void => {
          onClick(id);
        }}
        className={`spacing-m-b-3 ${className}`}
      >
        <Button {...buttonProps} isFullWidth textClasses="body-s" iconClassName="content-body" customClasses="button-container" />
      </li>
    );
  }
);
