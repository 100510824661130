import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Text, TextSize} from '@Components/text';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import styles from './controlled-list-item-type-4.module.scss';

export type ControlledListItemType4Props = BaseControlledListItem;

export function ControlledListItemType4({id = '', className = '', isSelected = false, onClick = $.noop, ...props}: ControlledListItemType4Props) {
  return (
    <li
      key={id}
      onClick={() => {
        onClick(id);
      }}
      className={`flex-row-align-center ${isSelected ? styles.containerSelected : styles.container} ${className}`}
    >
      <Icon className={`${styles.containerIcon} ${isSelected ? 'content-body-white' : ''}`} icon="icon-check-circle" size={IconSize.SIZE_ICON_20} />
      <Text val={props.text} className={` ${styles.containerText} ${isSelected ? 'content-body-white' : 'content-body'}`} size={TextSize.XSMALL} />
    </li>
  );
}
