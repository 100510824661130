import type {ReactElement} from 'react';
import React from 'react';
import styles from './pulsating-dot.module.scss';

interface PulsatingDotProps {
  containerClassname: string;
}

export function PulsatingDot({...props}: PulsatingDotProps): ReactElement {
  return (
    <div className={props.containerClassname}>
      <div className={`radius-round bg-secondary-100 ${styles.dot}`} />
      <div className={`radius-round bg-secondary-50 ${styles.pulse}`} />
    </div>
  );
}
