import React from 'react';
import {Text, TextSize} from '@Components/text';
import Divider from '@Components/divider';
import styles from './controlled-list-heading-item.module.scss';

export interface ControlledListHeadingItemProps {
  id: string;
  className?: string;
  text: string;
}

export function ControlledListHeadingItem({className = '', ...props}: ControlledListHeadingItemProps) {
  return (
    <li key={props.id} className={` ${styles.container} ${className || ''}`}>
      <Text className="content-heading" val={props.text} bold size={TextSize.XSMALL} />
      <Divider additionalClasses={`${styles.divider} spacing-m-b-2`} />
    </li>
  );
}
