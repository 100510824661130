import React from 'react';
import {Text, TextSize, TextType} from '@Components/text';
import {Pill, PILL_BACKGROUND_COLOR, PILL_CONTENT_TYPE, PILL_SIZE} from '@Components/pill';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import styles from './controlled-list-item-type-5.module.scss';

export interface ControlledListItemType5Props extends BaseControlledListItem {
  pill: PillData;
}

interface PillData {
  icon: string;
  text: string;
  textColorClass?: string;
  background?: PILL_BACKGROUND_COLOR;
  size?: PILL_SIZE;
  className?: string;
}

export function ControlledListItemType5({id = '', className = '', isSelected = false, onClick = $.noop, ...props}: ControlledListItemType5Props) {
  const getClassName = () => {
    let listItemClassName = `spacing-m-b-3 ${className} `;
    if (isSelected) {
      listItemClassName += styles.containerSelected;
    } else {
      listItemClassName += styles.container;
    }

    return listItemClassName;
  };

  return (
    <li
      key={id}
      onClick={() => {
        onClick(id);
      }}
      className={getClassName()}
    >
      <Text className={`${styles.text} hover-transition-all`} val={props.text} type={TextType.BODY} size={TextSize.MEDIUM} dangerouslySetInnerHTML />
      <Pill
        content={{
          type: PILL_CONTENT_TYPE.HYBRID,
          icon: props.pill.icon,
          text: props.pill.text,
          isBold: true,
          textColor: props.pill.textColorClass ?? (isSelected ? 'content-body-white' : 'content-primary'),
          iconColor: isSelected ? 'content-body-white' : 'content-body',
        }}
        background={props.pill.background ?? PILL_BACKGROUND_COLOR.PRIMARY_10}
        size={props.pill.size ?? PILL_SIZE.XSMALL}
        className={props.pill.className ?? styles.pill}
      />
    </li>
  );
}
