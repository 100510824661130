import type {Ref} from 'react';
import React from 'react';
import {Icon} from '@Components/icon-v2';
import {IconSize} from '@Components/icon-v2/icon.types';
import {Checkbox} from '@Components/checkbox';
import {Text, TextSize} from '@Components/text';
import {Pill, PILL_BACKGROUND_COLOR, PILL_CONTENT_TYPE, PILL_SIZE} from '@Components/pill';
import type { ThumbnailData} from '@Components/thumbnail';
import {Thumbnail, THUMBNAIL_TYPE} from '@Components/thumbnail';
import {CircularProgressLoader, LOADER_COLOR} from '@Components/circular-progress-loader';
import type {BaseControlledListItem} from '@Components/controlled-list/controlled-list.types';
import styles from './controlled-list-item-type-1.module.scss';

export interface ControlledListItemType1Props extends BaseControlledListItem {
  subText?: string;
  loading?: boolean;
  showEditIcon?: boolean;
  isDraggable?: boolean;
  thumbnail?: ThumbnailData;

  onClickEditIcon?(listItemId?: string): void;
}

export const ControlledListItemType1 = React.forwardRef(
  (
    {
      id = '',
      isDraggable = false,
      className = '',
      subText = '',
      loading = false,
      showEditIcon = false,
      isSelected = false,
      onClickEditIcon = $.noop,
      onClick = $.noop,
      ...props
    }: ControlledListItemType1Props,
    ref: Ref<HTMLLIElement>
  ) => {
    const displayLeftSubContainer = () => {
      return (
        <>
          <div className="flex-h-row">
            <Checkbox isChecked={isSelected} className="spacing-m-r-3" />
            {!loading ? (
              <Text val={props.text} size={TextSize.XSMALL} className={`spacing-m-r-2 content-body ${styles.text}`} />
            ) : (
              <Text val={window.i18next.t('pmwjs_fetching_data')} size={TextSize.XSMALL} bold={false} className="content-sub-text" />
            )}
          </div>
          <div className={`flexbox flex-1 flex-justify-end ${styles.thumbnailContainer}`}>
            {showThumbnail()}
            {subText && !loading ? (
              <Pill
                className="spacing-m-r-3"
                content={{
                  text: subText,
                  type: PILL_CONTENT_TYPE.TEXT,
                }}
                size={PILL_SIZE.XSMALL}
                background={isSelected ? PILL_BACKGROUND_COLOR.PRIMARY_20 : PILL_BACKGROUND_COLOR.PRIMARY_10}
              />
            ) : null}
          </div>
        </>
      );
    };

    const showThumbnail = () => {
      if (!props.thumbnail) {
        return null;
      }
      return <Thumbnail data={props.thumbnail} className={props.thumbnail.type === THUMBNAIL_TYPE.IMAGE ? styles.image : ''} />;
    };

    const displayRightSubContainer = () => {
      if (loading) {
        return (
          <div className=" flex-center spacing-m-t-3 spacing-m-r-3 spacing-m-b-3 spacing-m-l-4">
            <CircularProgressLoader color={LOADER_COLOR.PRIMARY} />
          </div>
        );
      }
      return (
        <div className={`${styles.rightSubContainer} flex-row-align-center`}>
          {showEditIcon ? (
            <div onClick={onClickEditIconHandler} className={`${styles.editBoxContainer} flex-center ${isSelected ? styles.selected : ''}`}>
              <Icon icon="icon-pencil" size={IconSize.SIZE_ICON_20} />
            </div>
          ) : null}
        </div>
      );
    };

    const displayListItemContent = () => {
      return (
        <>
          {isDraggable ? <Icon icon="icon-draggable" className="color-extra-light" size={IconSize.SIZE_ICON_16} /> : null}
          <div
            className={`${styles.listItemContainer} ${
              isDraggable ? styles.draggableListItemContainer : styles.listItemContainerWidth
            } spacing-p-0 flexbox flex-items-center flex-1 ${isSelected ? styles.selected : ''} ${className}`}
          >
            <div onClick={onClickHandler} className={`${styles.leftSubContainer} flexbox flex-items-center flex-1 ${isSelected ? styles.selected : ''}`}>
              {displayLeftSubContainer()}
            </div>
            {displayRightSubContainer()}
          </div>
        </>
      );
    };

    const onClickHandler = () => {
      if (onClick) {
        onClick();
      }
    };

    const onClickEditIconHandler = () => {
      if (onClickEditIcon) {
        onClickEditIcon();
      }
    };

    return (
      <li id={id} className={`${styles.container} flex-row-align-center spacing-p-0`} ref={ref} {...props}>
        {displayListItemContent()}
      </li>
    );
  }
);
